import { safeDefineElement } from '@/scripts/core/global';
import { CartDrawer } from '@/scripts/cart/cart-drawer'
import { CartDrawerItems } from '@/scripts/cart/cart-drawer-items'
import { CartRemoveButton } from '@/scripts/cart/cart-remove-button'
import { PredictiveSearch } from '@/scripts/optional/predictive-search';
import { QuantityInput } from '@/scripts/theme/quantity-input';
import { SearchForm } from '@/scripts/theme/search-form';
import { HeaderMenu } from '@/scripts/theme/header-menu';
import { SearchModal } from '@/scripts/theme/search-modal';
import { MenuDrawer } from '@/scripts/theme/menu-drawer';
import { HeaderDrawer } from '@/scripts/theme/header-drawer';
import { ModalDialog } from '@/scripts/theme/modal-dialog';
import { ModalOpener } from '@/scripts/theme/modal-opener';
import { CartProgressBar } from "@/scripts/cart/cart-progress-bar";
import { CartGiftOptions, GiftOptionsExternalOpener } from "@/scripts/cart/cart-gift-options";
import { CartNote } from "@/scripts/cart/cart-note";

safeDefineElement(QuantityInput)
safeDefineElement(CartDrawer)
safeDefineElement(CartDrawerItems)
safeDefineElement(CartRemoveButton)
safeDefineElement(SearchForm)
safeDefineElement(PredictiveSearch)
safeDefineElement(HeaderMenu)
safeDefineElement(SearchModal)
safeDefineElement(MenuDrawer)
safeDefineElement(HeaderDrawer)
safeDefineElement(ModalDialog)
safeDefineElement(ModalOpener)
safeDefineElement(CartProgressBar)
safeDefineElement(CartNote)
safeDefineElement(CartGiftOptions)
safeDefineElement(GiftOptionsExternalOpener)
