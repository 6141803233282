import { DetailsDisclosure } from '@/scripts/theme/details-disclosure'
import { qsaRequired, qsOptional, qsRequired } from "@/scripts/core/global";
import { type StickyHeader } from '@/scripts/theme/sticky-header'
import { SELECTORS } from '@/scripts/theme/constants'
import { SearchModal } from "@/scripts/theme/search-modal";

export class HeaderMenu extends DetailsDisclosure {
	static override htmlSelector = 'header-menu'
	header: StickyHeader | null
	opener: HTMLElement
	boundOnMouseLeave: EventListener
	searchModal?: SearchModal
	constructor() {
		super()
		this.header = qsRequired<StickyHeader>(SELECTORS.headerWrapper)
		this.opener = qsRequired('summary', this)
		this.opener.addEventListener('mouseenter', this.onHover.bind(this))
		this.boundOnMouseLeave = this.onMouseLeave.bind(this)
		this.searchModal = qsOptional('search-modal')
	}

	override getContent() {
		return qsRequired('[data-uc-megamenu-content]', this)
	}

	onMouseLeave(_: Event) {
		window.setTimeout(() => {
			this.close()
		}, 3)
		this.content.removeEventListener('mouseleave', this.boundOnMouseLeave)
	}

	closeSiblings(event: Event) {
		const relatedEls = qsaRequired<HeaderMenu>('header-menu')
		const siblings = Array.from(relatedEls).filter((el) => el !== this)
		if (siblings.length === 0) return
		const openSiblings = siblings.filter((el) => el.classList.contains('open'))
		if (openSiblings.length === 0) return
		this.setAttribute('data-uc-disable-animation', '')
    openSiblings.forEach((el) => {
			el.setAttribute('data-uc-disable-animation', '')
      el.boundOnMouseLeave(event)
    })
	}

	onHover(event: Event) {
    this.closeSiblings(event)
		this.open()
	}

	override onFocusOut() {
		//super.onFocusOut();
		console.log('onFocusOut')
	}

	open()  {
		if (!this.header) return
		this.searchModal?.close(false)
		this.header.preventHide = true
		this.mainDetailsToggle.open = true
		if (this.hasAttribute('data-uc-disable-animation')) {
			this.classList.add('open')
			this.header?.toggleActiveClass(true)
			window.setTimeout(() => {
				this.content.addEventListener('mouseleave', this.boundOnMouseLeave)
				this.removeAttribute('data-uc-disable-animation')
			}, 1)
		} else {
			window.setTimeout(() => {
				this.classList.add('open')
				this.header?.toggleActiveClass(true)
			}, 2)
			window.setTimeout(() => {
				this.content.addEventListener('mouseleave', this.boundOnMouseLeave)
			}, 400)
		}

	}

	override close()  {
		if (!this.header) return
		this.header.preventHide = false
		this.classList.remove('open')
		if (this.hasAttribute('data-uc-disable-animation')) {
			this.mainDetailsToggle.open = false
			this.removeAttribute('data-uc-disable-animation')
		} else {
			window.setTimeout(() => {
				this.mainDetailsToggle.open = false
				this.header?.toggleActiveClass(false)
				this.removeAttribute('data-uc-disable-animation')
			}, 501)
		}

	}

	override onToggle(event: Event) {
		event.preventDefault()
	}
}
