import { qsRequired, type SubscriberCallback } from '@/scripts/core/global'
import { UcoastEl } from '@/scripts/core/UcoastEl';

export class QuantityInput extends UcoastEl {
	static htmlSelector = 'quantity-input'
	input: HTMLInputElement
	changeEvent: Event
	quantityUpdateUnsubscriber: SubscriberCallback | undefined = undefined
	constructor() {
		super()
		this.input = qsRequired('input', this)
		this.changeEvent = new Event('change', { bubbles: true })
	}

	signalChangeEvent() {
		this.input.dispatchEvent(this.changeEvent)
	}

}
