import { UcoastEl } from "@/scripts/core/UcoastEl";
import { getAttributeOrThrow } from "@/scripts/core/global";

export class CartProgressBar extends UcoastEl {
  static htmlSelector = 'cart-progress-bar'
  threshold: number
  currentValue: number
  constructor() {
    super()
    this.threshold = parseInt(getAttributeOrThrow('data-uc-threshold', this))
    this.currentValue = parseInt(getAttributeOrThrow('data-uc-current-value', this))
  }

}
