import {
	currentTargetRequired,
	getAttributeOrThrow,
	qsaOptional,
	qsaRequired,
	qsOptional,
} from '@/scripts/core/global'
import { routes } from '@/scripts/setup'
import { fetchConfig, qsRequired } from '@/scripts/core/global'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { CartDrawer } from '@/scripts/cart/cart-drawer'

function formatUSD(value: number) {
	const dollarAmount = value / 100
	if (dollarAmount % 1 === 0) {
		return dollarAmount.toFixed(0)
	} else {
		return dollarAmount.toFixed(2)
	}
}

export class CartGiftOptions extends UcoastEl {
	static htmlSelector = 'cart-gift-options'
	opener: HTMLElement
	closer: HTMLButtonElement
	panel: HTMLElement
	details: HTMLDetailsElement
	isOpen: boolean
	priceEl: HTMLElement
	submit: HTMLButtonElement
	currentTotalPrice: number
	cardVariants: NodeListOf<HTMLButtonElement>
	currentCardVariantId: number | undefined
	boxVariants?: NodeListOf<HTMLButtonElement>
	currentBoxVariantId: number | undefined
	cart: CartDrawer
	allVariantIds: number[]

	constructor() {
		super()
		this.isOpen = false
		this.opener = qsRequired('summary', this)
		this.closer = qsRequired('[data-uc-closer]', this)
		this.panel = qsRequired('[data-uc-panel]', this)
		this.details = qsRequired('details', this)
		this.priceEl = qsRequired('[data-uc-price]', this)
		this.submit = qsRequired('button[data-uc-submit]', this)
		this.cart = qsRequired<CartDrawer>('cart-drawer')
		this.allVariantIds = []

		this.cardVariants = qsaRequired('button[data-uc-card-variant]', this)

		const currentCardVariant = qsOptional('[data-uc-card-variant][data-uc-active-card]', this)
		this.currentTotalPrice = currentCardVariant ? parseInt(
			getAttributeOrThrow('data-uc-card-price', currentCardVariant)
		) : 0
		this.currentCardVariantId = currentCardVariant ? parseInt(
			getAttributeOrThrow('data-uc-card-variant', currentCardVariant)
		) : undefined

		const boxVariants = qsaOptional('[data-uc-box-variant]', this)
		if (boxVariants) {
			this.boxVariants = qsaOptional('[data-uc-box-variant]', this)
			const currentBoxVariant = qsOptional('[data-uc-box-variant][data-uc-active-box]', this)
			if (currentBoxVariant) {
				this.currentTotalPrice += parseInt(
					getAttributeOrThrow('data-uc-box-price', currentBoxVariant)
				)
				this.currentBoxVariantId = parseInt(
					getAttributeOrThrow('data-uc-box-variant', currentBoxVariant)
				)
			}
		}

		this.opener.addEventListener('click', this.open.bind(this))
		this.closer.addEventListener('click', this.close.bind(this))

		this.cardVariants.forEach((variant) => {
			const variantId = parseInt(getAttributeOrThrow('data-uc-card-variant', variant))
			this.allVariantIds.push(variantId)
			variant.addEventListener('click', (e) => {
				e.preventDefault()
				const target = currentTargetRequired(e)
				const variantId = parseInt(getAttributeOrThrow('data-uc-card-variant', target))
				this.changeCard(variantId)
			})
		})

		this.boxVariants?.forEach((variant) => {
			const variantId = parseInt(getAttributeOrThrow('data-uc-box-variant', variant))
			this.allVariantIds.push(variantId)
			variant.addEventListener('click', (e) => {
				e.preventDefault()
				const target = currentTargetRequired(e)
				const variantId = parseInt(getAttributeOrThrow('data-uc-box-variant', target))
				this.changeBox(variantId)
			})
		})

		this.submit.addEventListener('click', (e) => {
			e.preventDefault()
			this.close()
			this.submitForm()
		})
	}

	updateValues() {
		const currentCardVariant = qsOptional('[data-uc-card-variant][data-uc-active-card]', this)
		this.currentTotalPrice = currentCardVariant ? parseInt(
			getAttributeOrThrow('data-uc-card-price', currentCardVariant)
		) : 0
		this.currentCardVariantId = currentCardVariant ? parseInt(
			getAttributeOrThrow('data-uc-card-variant', currentCardVariant)
		) : undefined
		const boxVariant = qsOptional('[data-uc-box-variant][data-uc-active-box]', this)
		this.currentBoxVariantId = boxVariant
			? parseInt(getAttributeOrThrow('data-uc-box-variant', boxVariant))
			: undefined
		const currentBoxPrice = boxVariant
			? parseInt(getAttributeOrThrow('data-uc-box-price', boxVariant))
			: undefined
		this.currentTotalPrice += currentBoxPrice ? currentBoxPrice : 0
		this.priceEl.innerHTML = `$${formatUSD(this.currentTotalPrice)}`
	}

	submitForm() {
		const sectionIdsToRender = this.cart
			.getSectionsToRender()
			.filter((section) => section.id)
			.map((section) => section.id)

		const options: { sections: string; sections_url: string; updates: Record<number, number> } =
			{
				sections: sectionIdsToRender.join(','),
				sections_url: window.location.pathname,
				updates: {},
			}
		this.allVariantIds.forEach((variantId) => {
			if (this.currentCardVariantId === variantId || this.currentBoxVariantId === variantId) {
				options.updates[variantId] = 1
			} else {
				options.updates[variantId] = 0
			}
		})

		console.log('this.allVariantIds', this.allVariantIds)
		console.log({ options })

		const body = JSON.stringify(options)

		fetch(`${routes.cart_update_url}`, { ...fetchConfig(), ...{ body } })
			.then((response) => response.json())
			.then((response) => {
				console.log({ response })
				this.cart.renderContents(response)
			})
	}
	changeCard(variantId: number) {
		console.log({
			variantId,
			currentCardVariantId: this.currentCardVariantId,
			cardVariants: this.cardVariants,
		})
		if (this.currentCardVariantId === variantId) {
			this.cardVariants?.forEach(
				(variant) => variant.removeAttribute('data-uc-active-card')
			)
		} else {
			this.cardVariants.forEach((variant) => {
				const cardVariantId = parseInt(getAttributeOrThrow('data-uc-card-variant', variant))
				if (variantId === cardVariantId) {
					variant.setAttribute('data-uc-active-card', '')
				} else {
					variant.removeAttribute('data-uc-active-card')
				}
			})
		}
		this.updateValues()
	}

	changeBox(variantId: number) {
		if (this.currentBoxVariantId === variantId) {
			this.boxVariants?.forEach((variant) => {
				variant.removeAttribute('data-uc-active-box')
			})
		} else {
			this.boxVariants?.forEach((variant) => {
				const boxVariantId = parseInt(getAttributeOrThrow('data-uc-box-variant', variant))
				if (variantId === boxVariantId) {
					variant.setAttribute('data-uc-active-box', '')
				} else {
					variant.removeAttribute('data-uc-active-box')
				}
			})
		}

		this.updateValues()
	}

	open(event: Event) {
		event.preventDefault()
		this.details.setAttribute('open', '')
		this.isOpen = true
		window.setTimeout(() => {
			// check for open state within timeout
			if (this.isOpen) {
				this.panel.classList.add('open')
			}
		}, 2)
	}
	close() {
		this.isOpen = false
		this.panel.classList.remove('open')
		window.setTimeout(() => {
			// check for open state within timeout
			if (!this.isOpen) {
				this.details.removeAttribute('open')
			}
		}, 301)
	}
}

export class GiftOptionsExternalOpener extends UcoastEl {
	static htmlSelector = 'gift-options-external-opener'
	constructor() {
		super()
		this.addEventListener('click', this.open.bind(this))
	}
	open(event: Event) {
		event.preventDefault()
		const cartGiftOptions = qsRequired<CartGiftOptions>('cart-gift-options')
		cartGiftOptions.open(event)
	}
}
